import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import * as _ from 'lodash';

import { Table, ButtonColumn, MergeTable, elements, icons } from '@united-talent-agency/julius-frontend-components';
import { Spinner } from '@united-talent-agency/components';
import { listPeople, savePerson, mergePerson } from '@united-talent-agency/julius-frontend-store';

import MergeForm from '../merge-form/merge-form';
import { openPersonProfile } from '../../../../../support/person';

class People extends React.Component {
  constructor(props) {
    super(props);
    const columns = [
      {
        key: 'name',
        name: 'Name',
        sortable: false,
        width: 500,
      },
      {
        key: 'count',
        name: 'Count',
        sortable: false,
        width: 300,
      },
      {
        key: 'entry',
        name: '',
        width: 100,
        sortable: false,
        formatter: (
          <ButtonColumn
            buttonText="Merge..."
            onClick={entry => {
              this.setState({
                selectedPeople: _.keyBy(entry, e => {
                  console.log(e);
                  return e._id;
                }),
              });
            }}
          />
        ),
      },
    ];

    this.state = {
      name: '',
      type: '',
      personName: '',
      personTitle: '',
      personCompany: '',
      personType: '',
      columns,
      modalIsOpen: false,
      selectedPeople: {},
      selectedValues: {},
      selectedCells: {},
      merging: false,
    };
    this.refreshPeople();
  }

  removeKey = (obj, key) => {
    return Object.keys(obj)
      .filter(k => k !== key)
      .reduce((newSelected, key) => {
        newSelected[key] = obj[key];
        return newSelected;
      }, {});
  };

  startMerge = () => {
    this.setState({ merging: true });
  };

  dispatchMerge = values => {
    const { selectedPeople } = this.state;
    const ids = Object.keys(selectedPeople);
    const { dispatch } = this.props;
    const actionBody = { values, ids };
    dispatch(mergePerson(actionBody));
    this.resetTableAndFilters();
  };

  cancelMerge = () => {
    this.resetTableAndFilters();
  };

  resetTableAndFilters() {
    this.clearFilters();
    this.setState({ selectedPeople: {}, selectedValues: {}, selectedCells: {}, merging: false });
  }

  clearFilters = () => {
    this.setState({ name: '', type: '' }, () => {
      this.refreshPeople(null, null, true);
    });
  };

  selectValue = (key, value, cell) => {
    this.setState({
      selectedValues: Object.assign(this.state.selectedValues, { [key]: value }, {}),
      selectedCells: Object.assign(this.state.selectedCells, { [cell.idx]: cell.rowIdx }, {}),
    });
  };

  removeFromMerge = id => {
    this.setState({
      selectedPeople: this.removeKey(this.state.selectedPeople, id),
      selectedValues: {},
      selectedCells: {},
    });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true, personName: '', personType: '' });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false, personName: '', personType: '' });
  };

  onTextChange = (field, text) => {
    this.setState({ [field]: text });
  };

  savePerson = () => {
    const { dispatch } = this.props;
    const person = {
      name: this.state.personName,
      title: this.state.personTitle,
      company: this.state.personCompany,
      type: this.state.personType,
    };
    dispatch(savePerson(person)).then(() => {
      this.setState({ modalIsOpen: false });
      //   window.open(`/profile/${this.props.createdId}?isSteward=true`);
    });
  };

  refreshPeople() {
    const { dispatch } = this.props;
    const query = [{ $duplicate: true }];
    dispatch(listPeople(query));
  }

  UNSAFE_componentWillMount() {
    this.refreshPeople();
  }

  handlePageClick = data => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.props.peopleFilters.itemsPerPage);
    this.props.peopleFilters.offset = offset;
    this.refreshPeople();
  };

  renderTables() {
    let { styles, people } = this.props;
    const { selectedPeople, selectedCells } = this.state;

    people = _.orderBy(people, ['count'], ['desc']);
    people = people.filter(person => {
      return (
        (this.state.selectedPeople &&
          Object.keys(this.state.selectedPeople).length > 0 &&
          !(person.name === this.state.selectedPeople[Object.keys(this.state.selectedPeople)[0]].name)) ||
        !this.state.selectedPeople ||
        Object.keys(this.state.selectedPeople).length === 0
      );
    });

    return people.length ? (
      <div>
        <h1>Duplicates - People</h1>
        <div className={styles.filterBar} />
        <div className={`${styles.table}`}>
          <Table
            rowSelection={this.props.rowSelection}
            onSelect={cell => {
              if (cell.idx < this.state.columns.length - 1) {
                openPersonProfile(people[cell.rowIdx]);
              }
            }}
            selectable={this.props.selectRow}
            columns={this.state.columns}
            rows={people}
          />
          {!_.isEmpty(this.state.selectedPeople) && (
            <div>
              <button onClick={this.startMerge} className={styles.newButton}>
                <i className={styles.newButtonIcon} />
                Merge Selected...
              </button>
              <MergeTable
                removeFromMerge={this.removeFromMerge}
                selected={selectedPeople}
                selectedCells={selectedCells}
                editAction={openPersonProfile}
                minHeight={300}
              />
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  renderMergeForm() {
    const { selectedPeople } = this.state;
    return (
      <MergeForm dispatchMerge={this.dispatchMerge} cancelMerge={this.cancelMerge} selectedPeople={selectedPeople} />
    );
  }

  render() {
    return this.state.merging ? this.renderMergeForm() : this.renderTables();
  }
}

const TABLE_WIDTH = '1000px';

const withStyles = styled({
  content: {
    fontSize: '12px',
    fontWeight: '100',
  },
  filterBar: {
    width: TABLE_WIDTH,
    height: '70px',
  },
  filterLabel: {
    float: 'left',
    fontSize: 16,
    fontWight: 1000,
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '10px',
  },
  pagination: {
    display: 'inline-block',
    float: 'right',
    paddingLeft: 0,
    marginLeft: 0,
    color: 'black',
    '& > li > a': {
      color: 'black !important',
    },
    '& > li': {
      display: 'inline-block',
      color: 'black !important',
      margin: 5,
      padding: 5,
    },
    '& > li.selected': {
      background: 'black',
    },
    '& > li.selected > a': {
      color: 'white !important',
    },
  },
  table: {
    width: TABLE_WIDTH,
  },
  filterField: {
    width: '150px',
    marginRight: 10,
    float: 'left',
  },
  header: {
    color: '#000',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '1',
    background: 'white',
    marginBottom: 5,
  },
  newButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    float: 'right',
  }),
  newButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  menuItem: {
    zIndex: 1000000,
  },
  clearFilters: {
    float: 'left',
    fontSize: 8,
    color: 'black',
    textDecoration: 'underline',
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '15px',
    cursor: 'pointer',
  },
  spinner: {
    marginTop: 100,
    marginLeft: 200,
  },
});

const withState = connect(state => {
  const { people, peopleCount, peopleFilters, createdId } = state.dashboard;
  return {
    people,
    peopleCount,
    peopleFilters,
    createdId,
  };
});

export default withRouter(withState(withStyles(People)));

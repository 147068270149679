import { profileUrl } from './urls';

export function openPersonProfile(person) {
  const personId = (person && person._id) || person;
  window.open(`${profileUrl}/profile/${personId}?isSteward=true`, '_blank');
}

export function openNewPersonProfile() {
  window.open(`${profileUrl}/person/`, '_blank');
}

export const selectPeopleWithPrimaryGroup = people =>
  people.map(person => {
    const primaryMembership = (person.groupMembership || []).find(membership => membership.primary);
    const primaryGroup =
      primaryMembership && primaryMembership.group
        ? { _id: primaryMembership.group._id, name: primaryMembership.group.name }
        : { _id: '', name: '' };
    return { ...person, primaryGroup };
  });

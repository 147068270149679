/* eslint-disable array-callback-return, no-extend-native */

// Import CSS before React components.
import 'bootstrap/dist/css/bootstrap.css';
import 'normalize.css/normalize.css';
import 'react-day-picker/lib/style.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './styles/card.css';
import './styles/scroll.css';
import './styles/select.css';
import './styles/data-grid.css';
import './styles/popover.css';

import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { initializeDataDog } from './support/dataDog';
import { createStoreWithOptions } from '@united-talent-agency/julius-frontend-store';
import { OnyxThemeProvider } from '@united-talent-agency/components';

import Routes from './routes';

import { apiServerUrl } from './support/urls';
const store = createStoreWithOptions({ apiServerUrl });

/**
 * Data Dog RUM tracking initialization
 *
 * active host is interrogated against the allowable env flags using this map:
 *    "prod": phonesheet.unitedtalent.com
 *    "staging": phonesheet.staging.unitedtalent.com
 *    "dev": phonesheet.dev.unitedtalent.com
 *    "local": localhost
 */
 initializeDataDog(window.location.hostname, ['local', 'staging', 'prod']);

function mount(App) {
  return render(
    <Provider store={store}>
      <OnyxThemeProvider>
        <App />
      </OnyxThemeProvider>
    </Provider>,
    document.getElementById('app')
  );
}

mount(Routes);

if (module.hot) {
  module.hot.accept('./routes', () => {
    mount(require('./routes').default);
  });
}

Array.prototype.moveToFront = function(callback) {
  var i = this.length;
  while (i--) {
    if (callback(this[i], i)) {
      this.unshift(this.splice(i, 1)[0]);
    }
  }
  return this;
};

/*
  Flattens the array of objects into a dictionay with the field as the given key.
*/
Array.prototype.toDict = function(field) {
  let dict = {};
  this.map(item => {
    dict[item[field]] = item;
  });
  return dict;
};

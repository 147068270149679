import React, { useState } from 'react';

import { helpers, styled } from 'react-free-style';

import { Input } from '@united-talent-agency/julius-frontend-components';
import { Spinner } from '@united-talent-agency/components';

import * as elements from '../../../../../styles/elements';
import * as icons from '../../../../../styles/icons';

const determineRoles = companies => {
  return companies.reduce(
    (accRoles, company) => {
      if (company.roles) {
        if (company.roles.projects) {
          if (company.roles.projects.network) {
            accRoles.projects.network = {};
          }
          if (company.roles.projects.productionCompany) {
            accRoles.projects.productionCompany = {};
          }
          if (company.roles.projects.talentAgency) {
            accRoles.projects.talentAgency = {};
          }
          if (company.roles.projects.studio) {
            accRoles.projects.studio = {};
          }
          if (company.roles.projects.promoter) {
            accRoles.projects.promoter = {};
          }
          if (company.roles.projects.parentCompany) {
            accRoles.projects.parentCompany = {};
          }
        }
      }
      return accRoles;
    },
    { projects: {} }
  );
};

const getUniqueScalar = (companies, scalar) => [
  ...new Set(companies.map(company => company[scalar]).filter(uniqueValues => !!uniqueValues)),
];

const TextSelector = ({ choices, title, handleChange, handleClick, value }) => {
  return (
    <>
      <Input className="mt-2" title={title} value={value} onChange={handleChange} />
      {choices.map((choice, index) => (
        <div
          style={{
            fontWeight: 100,
            fontSize: '12px',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          className="text-selector-choice"
          key={index}
          onClick={handleClick(choice)}
        >
          {choice}
        </div>
      ))}
    </>
  );
};

const MergeCompaniesForm = ({ styles, selectedCompanies, finishMerge, cancelMerge }) => {
  const uniqueNames = getUniqueScalar(selectedCompanies, 'name') || [];
  const uniqueNetsuiteId = getUniqueScalar(selectedCompanies, 'netsuiteId');
  const uniqueDistributions = getUniqueScalar(selectedCompanies, 'distribution');
  const uniqueOriginatingDepartments = getUniqueScalar(selectedCompanies, 'originatingDepartment');
  const uniqueClientStatuses = getUniqueScalar(selectedCompanies, 'clientStatus');

  const [isMerging, setIsMerging] = useState(false);
  const [name, setName] = useState(uniqueNames[0] || '');
  const [netsuiteId, setNetsuiteId] = useState(uniqueNetsuiteId[0] || '');
  const [distribution, setDistribution] = useState(uniqueDistributions[0] || '');
  const [originatingDepartment, setOriginatingDepartment] = useState(uniqueOriginatingDepartments[0] || '');
  const [clientStatus, setClientStatus] = useState(uniqueClientStatuses[0] || '');

  return (
    <div className={styles.outerDiv}>
      {isMerging ? (
        <div style={{ margin: '10rem 20rem' }}>
          <Spinner size={200} />
        </div>
      ) : (
        <>
          <div className="container">
            <span className={styles.title}>Company Data Merge</span>
            {uniqueNames.length > 0 && (
              <TextSelector
                choices={uniqueNames}
                title="Name"
                handleChange={name => setName(name)}
                handleClick={name => () => setName(name)}
                value={name}
              />
            )}
            {uniqueNetsuiteId.length > 0 && (
              <TextSelector
                choices={uniqueNetsuiteId}
                title="Netsuite ID"
                handleChange={netsuiteId => setNetsuiteId(netsuiteId)}
                handleClick={netsuiteId => () => setNetsuiteId(netsuiteId)}
                value={netsuiteId}
              />
            )}
            {uniqueDistributions.length > 0 && (
              <TextSelector
                choices={uniqueDistributions}
                title="Distributions"
                handleChange={distribution => setDistribution(distribution)}
                handleClick={distribution => () => setDistribution(distribution)}
                value={distribution}
              />
            )}
            {uniqueOriginatingDepartments.length > 0 && (
              <TextSelector
                choices={uniqueOriginatingDepartments}
                title="Originating Department"
                handleChange={originatingDepartment => setOriginatingDepartment(originatingDepartment)}
                handleClick={originatingDepartment => () => setOriginatingDepartment(originatingDepartment)}
                value={originatingDepartment}
              />
            )}
            {uniqueClientStatuses.length > 0 && (
              <TextSelector
                choices={uniqueClientStatuses}
                title="Client Status"
                handleChange={clientStatus => setClientStatus(clientStatus)}
                handleClick={clientStatus => () => setClientStatus(clientStatus)}
                value={clientStatus}
              />
            )}
          </div>
          <div className="mt-3">
            <button onClick={cancelMerge} className={styles.cancelButton}>
              <i className={styles.cancelButtonIcon} />
              Cancel Merge
            </button>
            <button
              onClick={() => {
                setIsMerging(true);
                const ids = selectedCompanies.map(({ _id }) => _id);
                const roles = determineRoles(selectedCompanies);
                const values = { name, netsuiteId, distribution, originatingDepartment, roles, clientStatus };
                finishMerge({ ids, values });
              }}
              className={styles.finishButton}
            >
              <i className={styles.finishButtonIcon} />
              Finish Merge
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const withStyles = styled({
  finishButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    float: 'right',
  }),
  finishButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  cancelButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    float: 'left',
  }),
  cancelButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.cross
  ),
  outerDiv: {
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16pt',
    marginTop: 20,
    marginBottom: 20,
  },
  row: {
    marginTop: 20,
    border: '1px solid black',
    padding: '5px',
  },
});

export default withStyles(MergeCompaniesForm);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import { notify } from 'react-notify-toast';
import ReactPaginate from 'react-paginate';
import _, { debounce } from 'lodash';

import { Table, ButtonColumn, Input, elements, icons } from '@united-talent-agency/julius-frontend-components';
import { mergeCompanies } from '@united-talent-agency/julius-frontend-store';

import MergeTable from '../../../../../components/merge-table/merge-table';
import MergeCompaniesForm from './merge-companies-form';
import { getGroups } from '../../../../../api/groups';

const BASE_PROFILE_URL = process.env.REACT_APP_PROFILE_URL;

const MergeForm = ({ selectedCompanies, cancelMerge, finishMerge }) => (
  <MergeCompaniesForm
    selectedCompanies={Object.values(selectedCompanies)}
    cancelMerge={cancelMerge}
    finishMerge={finishMerge}
  />
);

const MergeTables = ({
  styles,
  refreshCompanies,
  name,
  clearFilters,
  selectedCompanies,
  companies = [],
  columns,
  rowSelection,
  selectRow,
  handlePageClick,
  companiesFilters,
  companiesCount,
  removeFromMerge,
  selectedCells,
  beginMerge,
}) => {
  const selectedCompaniesKeys = Object.keys(selectedCompanies);
  const companiesLinkedToProfiles = companies
    .filter(({ _id }) => !selectedCompaniesKeys.includes(_id))
    .map(company => {
      company.profileLink = (
        <a
          style={{ color: 'black' }}
          target="_blank"
          rel="noopener noreferrer"
          href={`${BASE_PROFILE_URL}/company/${company._id}`}
        >
          {company.name}
        </a>
      );
      return company;
    });
  return (
    <div className={styles.container}>
      <h1>Merge -- Companies</h1>
      <div className={styles.filterBar}>
        <span className={styles.filterLabel}>Filters:</span>
        <Input title="Name" className={styles.filterField} value={name} onChange={debounce(refreshCompanies, 400)} />
        <span className={styles.clearFilters} onClick={clearFilters}>
          Clear Filters
        </span>
        <a href={`${BASE_PROFILE_URL}/company`} target="_blank" rel="noopener noreferrer" className={styles.newButton}>
          <i className={styles.newButtonIcon} />
          Create Company
        </a>
      </div>
      <>
        <Table rowSelection={rowSelection} selectable={selectRow} columns={columns} rows={companiesLinkedToProfiles} />
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName={styles.breakPagination}
          pageCount={Math.ceil(companiesCount / companiesFilters.itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={styles.pagination}
          subContainerClassName={styles.pages}
          activeClassName={styles.activePagination}
        />
      </>
      {!_.isEmpty(selectedCompanies) && (
        <>
          <button onClick={beginMerge} className={styles.newButton}>
            <i className={styles.newButtonIcon} />
            Merge Selected...
          </button>
          <MergeTable
            removeFromMerge={removeFromMerge}
            selected={selectedCompanies}
            selectedCells={selectedCells}
            editAction={() => {}}
            minHeight={300}
          />
        </>
      )}
    </div>
  );
};

class MergeCompanies extends Component {
  constructor(props) {
    super(props);
    const columns = [
      {
        key: 'profileLink',
        name: 'Name',
        sortable: false,
        width: 200,
      },
      {
        key: '_id',
        name: '',
        width: 100,
        sortable: false,
        formatter: (
          <ButtonColumn
            buttonText="Merge..."
            onClick={id => {
              const target = this.state.companies.find(company => company._id === id);
              this.setState({
                selectedCompanies: { ...this.state.selectedCompanies, [id]: target },
              });
            }}
          />
        ),
      },
    ];
    this.state = {
      name: '',
      columns,
      companies: [],
      companiesCount: 0,
      offset: 0,
      itemsPerPage: 10,
      selectedCompanies: {},
      merging: false,
      mergingCompanies: false,
    };
    this.refreshCompanies();
  }

  removeKey = (obj, key) => {
    return Object.keys(obj)
      .filter(k => k !== key)
      .reduce((newSelected, key) => {
        newSelected[key] = obj[key];
        return newSelected;
      }, {});
  };

  removeFromMerge = id => {
    this.setState({
      selectedCompanies: this.removeKey(this.state.selectedCompanies, id),
      selectedValues: {},
      selectedCells: {},
    });
  };

  async refreshCompanies(name, isClear) {
    const { offset, itemsPerPage } = this.state;
    if (name) {
      this.setState({ name: name });
    }
    let search = '';
    if (!isClear) {
      search = name || this.state.name || null;
    }

    const response = await getGroups(search, offset, itemsPerPage);
    const { total, skip, limit } = response && response.meta;
    this.setState({
      companies: response.data,
      companiesCount: total,
      offset: skip,
      itemsPerPage: limit,
    });
  }

  handlePageClick = ({ selected }) => {
    const { itemsPerPage } = this.state;
    const offset = Math.ceil(selected * itemsPerPage);
    this.setState({ offset }, this.refreshCompanies);
  };

  merge = async ({ values, ids }) => {
    const { dispatch } = this.props;
    const { name } = values;
    await dispatch(mergeCompanies({ values, ids }));
    this.resetTableAndFilters();
    this.refreshCompanies(name, false);
    notify.show(`Merge successful`, 'success');
  };

  cancelMerge = () => {
    this.resetTableAndFilters();
  };

  resetTableAndFilters() {
    this.setState({ selectedCompanies: {}, selectedValues: {}, selectedCells: {}, merging: false });
  }

  render() {
    const { styles, rowSelection, selectRow, dispatch } = this.props;
    const {
      selectedCompanies,
      merging,
      name,
      columns,
      company,
      companies,
      companiesCount,
      offset,
      itemsPerPage,
      selectedCells,
      mergingCompanies,
    } = this.state;
    return merging ? (
      <MergeForm selectedCompanies={selectedCompanies} cancelMerge={this.cancelMerge} finishMerge={this.merge} />
    ) : (
      <MergeTables
        mergingCompanies={mergingCompanies}
        styles={styles}
        selectedCompanies={selectedCompanies}
        companies={companies}
        name={name}
        company={company}
        dispatch={dispatch}
        companiesFilters={{ offset, itemsPerPage }}
        handlePageClick={this.handlePageClick}
        companiesCount={companiesCount}
        columns={columns}
        beginMerge={() => this.setState({ merging: true })}
        removeFromMerge={this.removeFromMerge}
        selectedCells={selectedCells}
        rowSelection={rowSelection}
        selectRow={selectRow}
        refreshCompanies={e => this.refreshCompanies(e, null)}
        clearFilters={() => {
          this.setState({ name: '' }, () => {
            this.refreshCompanies(null, true);
          });
        }}
      />
    );
  }
}

const withStyles = styled({
  container: {
    width: '100%',
  },
  filterBar: {
    width: '100%',
    height: '70px',
  },
  filterLabel: {
    float: 'left',
    fontSize: '16px',
    fontWight: 1000,
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '10px',
  },
  pagination: {
    display: 'inline-block',
    float: 'right',
    paddingLeft: 0,
    marginLeft: 0,
    color: 'black',
    '& > li > a': {
      color: 'black !important',
    },
    '& > li': {
      display: 'inline-block',
      color: 'black !important',
      margin: '5px',
      padding: '5px',
    },
    '& > li.selected': {
      background: 'black',
    },
    '& > li.selected > a': {
      color: 'white !important',
    },
  },
  filterField: {
    width: '150px',
    marginRight: '10px',
    float: 'left',
  },
  header: {
    color: '#000',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '1',
    background: 'white',
    marginBottom: '5px',
  },
  newButton: helpers.merge(elements.button, elements.actionable, {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    float: 'right',
    fontSize: '10px',
    '&:hover': {
      textDecoration: 'none',
      color: 'black',
    },
  }),
  newButtonIcon: helpers.merge(
    {
      marginRight: '10px',
    },
    icons.plus
  ),
  menuItem: {
    zIndex: 1000000,
  },
  clearFilters: {
    float: 'left',
    fontSize: '8px',
    color: 'black',
    textDecoration: 'underline',
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '15px',
    cursor: 'pointer',
  },
  spinner: {
    position: 'relative',
    zIndex: 1000,
    top: '250px',
    left: '50%',
  },

  hidden: {
    display: 'none',
  },
});

// keeping connect just to have access to dispatch function
export default withRouter(withStyles(connect()(MergeCompanies)));

import React, { Component } from 'react';

import { Table, ButtonColumn, CountColumn, ObjectColumn } from '@united-talent-agency/julius-frontend-components';

import { helpers, styled } from 'react-free-style';

import * as elements from '../../styles/elements';
import * as icons from '../../styles/icons';

export class MergeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          key: 'name',
          name: 'Name',
          sortable: false,
        },
        {
          key: 'type',
          name: 'Type',
          sortable: false,
        },
        {
          key: 'primaryGroup',
          name: 'Company',
          sortable: false,
          formatter: ObjectColumn,
        },
        {
          key: 'profile_pic',
          name: 'Picture',
          sortable: false,
        },
        {
          key: 'contacts',
          name: 'Contacts',
          sortable: false,
          formatter: CountColumn,
        },
        {
          key: 'addresses',
          name: 'Addresses',
          sortable: false,
          formatter: CountColumn,
        },
        {
          key: 'remove',
          name: '',
          sortable: false,
          formatter: <ButtonColumn buttonText="Remove" onClick={() => {}} />,
          events: {
            onClick: (ev, args) => {
              this.props.removeFromMerge(Object.values(this.props.selected)[args.rowIdx]._id);
            },
          },
        },
      ],
    };
  }

  render() {
    const { selected, selectedCells, editAction } = this.props;
    const selectedValues = Object.values(selected);
    const { columns } = this.state;
    return (
      <div>
        <Table
          selectable={this.props.selectRow}
          columns={columns}
          rows={selectedValues}
          onSelect={cell => {
            if (cell.idx < this.state.columns.length - 1) {
              editAction(selectedValues[cell.rowIdx]);
            }
          }}
          minHeight={300}
          selectedCells={selectedCells}
        />
      </div>
    );
  }
}

const withStyles = styled({
  content: {
    fontSize: '12px',
    fontWeight: 100,
  },
  filterBar: {
    flex: 1,
    height: '70px',
  },
  filterLabel: {
    float: 'left',
    fontSize: '16px',
    fontWight: 1000,
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '10px',
  },
  pagination: {
    display: 'inline-block',
    float: 'right',
    paddingLeft: 0,
    marginLeft: 0,
    color: '#141414',
    '& > li > a': {
      color: '#141414 !important',
    },
    '& > li': {
      display: 'inline-block',
      color: '#141414 !important',
      margin: '5px',
      padding: '5px',
    },
    '& > li.selected': {
      background: '#141414',
    },
    '& > li.selected > a': {
      color: 'white !important',
    },
  },
  table: {
    border: '1px solid #141414',
  },
  filterField: {
    width: '150px',
    marginRight: '10px',
    float: 'left',
  },
  header: {
    color: '#141414',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: 1,
    background: 'white',
    marginBottom: '5px',
  },
  newButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#141414',
    float: 'right',
  }),
  newButtonIcon: helpers.merge(
    {
      marginRight: '10px',
    },
    icons.plus
  ),
  menuItem: {
    zIndex: 1000000,
  },
  clearFilters: {
    float: 'left',
    fontSize: '8px',
    color: '#141414',
    textDecoration: 'underline',
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '15px',
    cursor: 'pointer',
  },
});

export default withStyles(MergeTable);

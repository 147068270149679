import { helpers } from 'react-free-style';
import { icon } from './elements';

import {
  menuUrl,
  gotoUrl,
  pencilUrl,
  trashUrl,
  questionMarkUrl,
  filterUrl,
  squareUrl,
  squareCrossedUrl,
  backUrl,
  checkUrl,
  checkBlackUrl,
  crossUrl,
  crossBlackUrl,
  plusUrl,
  starUrl,
  starActiveUrl,
  starOutlineUrl,
  phoneUrl,
  emailUrl,
  homeUrl,
  mobileUrl,
  messageUrl,
  calendarUrl,
  archiveUrl,
  chevronUpUrl,
  chevronDownUrl,
} from '@united-talent-agency/julius-frontend-components';

export const menu = helpers.merge(
  {
    width: 16,
    height: 10,
    backgroundImage: helpers.url(menuUrl),
  },
  icon
);

export const goto = helpers.merge(
  {
    width: 16,
    height: 16,
    backgroundImage: helpers.url(gotoUrl),
  },
  icon
);

export const pencil = helpers.merge(
  {
    width: 10,
    height: 10,
    backgroundImage: helpers.url(pencilUrl),
  },
  icon
);

export const filter = helpers.merge(
  {
    width: 16,
    height: 10,
    backgroundImage: helpers.url(filterUrl),
  },
  icon
);

export const square = helpers.merge(
  {
    width: 24,
    height: 24,
    backgroundImage: helpers.url(squareUrl),
  },
  icon
);

export const squareCrossed = helpers.merge(
  {
    width: 24,
    height: 24,
    backgroundImage: helpers.url(squareCrossedUrl),
  },
  icon
);

export const back = helpers.merge(
  {
    width: 16,
    height: 16,
    backgroundImage: helpers.url(backUrl),
  },
  icon
);

export const checkBlack = helpers.merge(
  {
    width: 10,
    height: 7,
    backgroundImage: helpers.url(checkBlackUrl),
  },
  icon
);

export const crossBlack = helpers.merge(
  {
    width: 16,
    height: 16,
    backgroundImage: helpers.url(crossBlackUrl),
  },
  icon
);

export const smallCrossBlack = helpers.merge(
  {
    width: 10,
    height: 10,
    backgroundImage: helpers.url(crossBlackUrl),
  },
  icon
);

export const check = helpers.merge(
  {
    width: 13,
    height: 10,
    backgroundImage: helpers.url(checkUrl),
  },
  icon
);

export const cross = helpers.merge(
  {
    width: 10,
    height: 10,
    backgroundImage: helpers.url(crossUrl),
  },
  icon
);

export const trashcan = helpers.merge(
  {
    width: 10,
    height: 10,
    backgroundImage: helpers.url(trashUrl),
  },
  icon
);

export const unknown = helpers.merge(
  {
    width: 10,
    height: 10,
    backgroundImage: helpers.url(questionMarkUrl),
  },
  icon
);

export const plus = helpers.merge(
  {
    width: 10,
    height: 10,
    backgroundImage: helpers.url(plusUrl),
  },
  icon
);

export const star = helpers.merge(
  {
    width: 12,
    height: 12,
    backgroundImage: helpers.url(starUrl),
  },
  icon
);

export const starActive = helpers.merge(
  {
    width: 12,
    height: 12,
    backgroundImage: helpers.url(starActiveUrl),
  },
  icon
);

export const starOutline = helpers.merge(
  {
    width: 12,
    height: 12,
    backgroundImage: helpers.url(starOutlineUrl),
  },
  icon
);

export const phone = helpers.merge(
  {
    width: 10,
    height: 10,
    backgroundImage: helpers.url(phoneUrl),
  },
  icon
);

export const office = helpers.merge(
  {
    width: 10,
    height: 10,
  },
  icon
);

export const email = helpers.merge(
  {
    width: 10,
    height: 7,
    backgroundImage: helpers.url(emailUrl),
  },
  icon
);

export const home = helpers.merge(
  {
    width: 10,
    height: 8,
    backgroundImage: helpers.url(homeUrl),
  },
  icon
);

export const mobile = helpers.merge(
  {
    width: 6,
    height: 10,
    backgroundImage: helpers.url(mobileUrl),
  },
  icon
);

export const message = helpers.merge(
  {
    width: 10,
    height: 10,
    backgroundImage: helpers.url(messageUrl),
  },
  icon
);

export const chevronUp = helpers.merge(
  {
    width: 10,
    height: 6,
    backgroundImage: helpers.url(chevronUpUrl),
  },
  icon
);

export const chevronDown = helpers.merge(
  {
    width: 10,
    height: 6,
    backgroundImage: helpers.url(chevronDownUrl),
  },
  icon
);

export const calendar = helpers.merge(
  {
    width: 10,
    height: 10,
    backgroundImage: helpers.url(calendarUrl),
  },
  icon
);

export const archive = helpers.merge(
  {
    width: 16,
    height: 16,
    backgroundImage: helpers.url(archiveUrl),
  },
  icon
);

/**
 * Pad a string.
 */
export function pad(num, prefix = '00', last = 2) {
  return `${prefix}${num}`.slice(-last);
}

/**
 * Check if two dates are the same.
 */
export function isDateEqual(a, b) {
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
}

/**
 * Create a date instance at beginning of day.
 */
export function today() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

const TIME_REGEXP = /(\d{1,2})(?:[^\w]*(\d{1,2})(?:[^\w]*(\d{1,2}))?)?\s*(p)?/i;

/**
 * Parse a time string into hour, minutes, seconds.
 */
export function parseTime(value) {
  const result = TIME_REGEXP.exec(value);

  if (!result) return;

  let hours = parseInt(result[1], 10);
  let minutes = result[2] ? parseInt(result[2], 10) : 0;
  let seconds = result[3] ? parseInt(result[3], 10) : 0;

  if (result[4] && hours < 12) hours += 12;

  if (seconds >= 60) minutes += ~~(seconds / 60);
  if (minutes >= 60) hours += ~~(minutes / 60);

  return [hours % 24, minutes % 60, seconds % 60];
}

/**
 * Format a time string.
 */
export function toTime(date) {
  const hours = date.getHours();
  const ampm = hours >= 12 ? 'pm' : 'am';

  return `${date.getHours() % 12 > 0 ? date.getHours() % 12 : 12}:${pad(date.getMinutes())}${ampm}`;
}

/**
 * Format a date string.
 */
export function toDateString(date) {
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
}

/**
 * Return a date time offset from a specific date instance.
 */
export function dateTime(value, hours = 0, minutes = 0, seconds = 0) {
  const year = value.getFullYear();
  const month = value.getMonth();
  const date = value.getDate();

  return new Date(year, month, date, hours, minutes, seconds);
}

/**
 * Return a date from a string.
 */
export function fromDateString(value, hours = 0, minutes = 0, seconds = 0) {
  const monthIndex = value.indexOf('-');
  const dateIndex = value.indexOf('-', monthIndex + 1);

  const year = parseInt(value.substr(0, monthIndex), 10);
  const month = parseInt(value.substr(monthIndex + 1, dateIndex), 10);
  const date = parseInt(value.substr(dateIndex + 1), 10);

  return new Date(year, month - 1, date, hours, minutes, seconds);
}

/**
 * Return unix time used by the API from date.
 */
export function toUnixTime(date) {
  return date.getTime();
}

/**
 * Create date from unix time used by the API.
 */
export function fromUnixTime(value) {
  return new Date(value);
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import * as _ from 'lodash';

import { CompanyInput, Input, Select, AddressInfo, Avatar } from '@united-talent-agency/julius-frontend-components';
import { searchCompany, loadPerson, personTypes } from '@united-talent-agency/julius-frontend-store';
import { Spinner } from '@united-talent-agency/components';

import * as elements from '../../../../../styles/elements';
import * as icons from '../../../../../styles/icons';

const selectableTypes = [personTypes.shared, personTypes.client];

class MergeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: undefined,
      companyId: undefined,
      companyName: undefined,
      companyResults: null,
      profile_pic: undefined,
      type: undefined,
      website_id: '',
      slug: '',
      artistName: '',
      artistWebsite: '',
      bandsintown: '',
      tags: [],
      role: '',
      gender: '',
      relationships: [],
      territoriesOfRepresentation: '',
      representationDepartments: [],
      representationDepartmentIds: [],
      social: [],
      representedByAgencies: [],
      roles: {},
      contacts: {},
      addresses: {},
      selectedAddresses: [],
      selectedContacts: [],
      selectedCompanies: [],
      selectedNames: [],
      selectedPictures: [],
      selectedNetsuiteIds: [],
      selectedTerr: [],
      selectedWebsiteIds: [],
      selectedSlugs: [],
      selectedRoles: [],
      selectedGenders: [],
      selectedBandsInTown: [],
      selectedArtistWebsite: [],
      selectedArtistName: [],
      selectedTypes: [],
    };
  }

  distinctPeopleArrayItems(people, attribute) {
    const distinctItems = people
      .map(p => {
        return p[attribute] ? p[attribute] : [];
      })
      .reduce((obj, items) => {
        items.forEach(item => {
          obj[JSON.stringify(item)] = item;
        });
        return obj;
      }, {});

    return Object.keys(distinctItems).map(key => {
      return distinctItems[key];
    });
  }
  async componentDidMount() {
    const selectedValues = await this.getPeople();

    const representationDepartmentIds = this.distinctPeopleArrayItems(selectedValues, 'representationDepartmentIds');
    const representationDepartments = this.distinctPeopleArrayItems(selectedValues, 'representationDepartments');
    const social = this.distinctPeopleArrayItems(selectedValues, 'social');
    const tags = this.distinctPeopleArrayItems(selectedValues, 'tags');
    const relationships = this.distinctPeopleArrayItems(selectedValues, 'relationships');
    const representedByAgencies = this.distinctPeopleArrayItems(selectedValues, 'representedByAgencies');
    const roles = selectedValues.reduce((obj, person) => {
      return _.merge(obj, person.roles);
    }, {});

    const selectedAddresses = this.filterAddresses(selectedValues);
    const selectedCompanies = this.filterCompanies(selectedValues);
    const selectedContacts = this.filterContacts(selectedValues);
    const selectedNames = this.filterNames(selectedValues);
    const selectedWebsiteIds = this.filterWebsiteIds(selectedValues);
    const selectedSlugs = this.filterSlugs(selectedValues);
    const selectedRoles = this.filterRoles(selectedValues);
    const selectedGenders = this.filterGenders(selectedValues);
    const selectedBandsInTown = this.filterBandsInTown(selectedValues);
    const selectedTerr = this.filterTerritoriesOfRepresentation(selectedValues);
    const selectedArtistNames = this.filterArtistName(selectedValues);
    const selectedArtistWebsite = this.filterArtistWebsite(selectedValues);
    const selectedPictures = this.filterPictures(selectedValues);
    const selectedNetsuiteIds = this.filterNetsuiteIds(selectedValues);
    const selectedTypes = this.filterTypes();

    const defaultCompanyId = selectedCompanies.length > 0 ? selectedCompanies[0]._id : undefined;
    const defaultCompanyName = selectedCompanies.length > 0 ? selectedCompanies[0].name : undefined;
    const defaultName = selectedNames[0];
    const defaultPicture = selectedPictures.length > 0 ? selectedPictures[0] : undefined;
    const defaultType = selectedTypes.length > 0 ? selectedTypes[0].content : undefined;

    this.setState({
      companyName: defaultCompanyName,
      companyId: defaultCompanyId,
      name: defaultName,
      picture: defaultPicture,
      type: defaultType,
      selectedAddresses,
      selectedCompanies,
      selectedContacts,
      selectedNames,
      selectedPictures,
      selectedTypes,
      selectedWebsiteIds,
      selectedSlugs,
      selectedTerr,
      selectedNetsuiteIds,
      selectedRoles,
      selectedGenders,
      selectedBandsInTown,
      selectedArtistNames,
      selectedArtistWebsite,
      representationDepartmentIds,
      representationDepartments,
      social,
      tags,
      relationships,
      representedByAgencies,
      roles,
    });
  }

  async getPeople() {
    /*
    Implemented this method to deal with inconsistencies in search results from duplicate / merge pages
    If we can make the $duplicate search return a full company object instead of just the id,
      this redundant fetching will be unnecessary and can be replaced with simply:
    return Object.values(this.props.selectedPeople);
    */
    const { selectedPeople, dispatch } = this.props;
    const selectedIds = Object.keys(selectedPeople);
    const fetchedPeople = await Promise.all(selectedIds.map(id => dispatch(loadPerson(id))));
    return fetchedPeople.map(person => person.body);
  }

  onTextChange = (field, text) => {
    this.setState({ [field]: text });
  };

  async searchCompany(name) {
    const { dispatch } = this.props;
    const results = (await dispatch(searchCompany(name))).body.data;
    this.setState({ companyResults: results });
  }

  selectCompany(company) {
    this.setState({ company, companyName: company.name, companyResults: null });
    this.onTextChange({ companyId: company._id });
  }

  filterNetsuiteIds(selectedValues) {
    return [...new Set(selectedValues.map(({ netsuiteId }) => netsuiteId).filter(netsuiteId => !!netsuiteId))];
  }

  filterNames(selectedValues) {
    return [...new Set(selectedValues.map(({ name }) => name).filter(name => !!name))];
  }

  filterWebsiteIds(selectedValues) {
    return [...new Set(selectedValues.map(({ website_id }) => website_id).filter(website_id => !!website_id))];
  }

  filterSlugs(selectedValues) {
    return [...new Set(selectedValues.map(({ slug }) => slug).filter(slug => !!slug))];
  }

  filterRoles(selectedValues) {
    return [...new Set(selectedValues.map(({ role }) => role).filter(role => !!role))];
  }

  filterGenders(selectedValues) {
    return [...new Set(selectedValues.map(({ gender }) => gender).filter(gender => !!gender))];
  }

  filterBandsInTown(selectedValues) {
    return [...new Set(selectedValues.map(({ bandsintown }) => bandsintown).filter(bandsintown => !!bandsintown))];
  }

  filterTerritoriesOfRepresentation(selectedValues) {
    return [
      ...new Set(
        selectedValues
          .map(({ territoriesOfRepresentation }) => territoriesOfRepresentation)
          .filter(territoriesOfRepresentation => !!territoriesOfRepresentation)
      ),
    ];
  }

  filterArtistName(selectedValues) {
    return [...new Set(selectedValues.map(({ artistName }) => artistName).filter(artistName => !!artistName))];
  }

  filterArtistWebsite(selectedValues) {
    return [
      ...new Set(selectedValues.map(({ artistWebsite }) => artistWebsite).filter(artistWebsite => !!artistWebsite)),
    ];
  }

  filterPictures(selectedValues) {
    return [
      ...new Set(
        selectedValues
          .map(({ profile_pic }) => (profile_pic ? profile_pic : Avatar))
          .filter(profile_pic => !!profile_pic)
      ),
    ];
  }

  filterTypes() {
    return selectableTypes.map(type => {
      return {
        content: type,
        key: type,
        active: type === this.state.type,
        onClick: () => this.setState({ type }),
      };
    });
  }

  filterCompanies(selectedValues) {
    const selectedCompanies = selectedValues
      .map(person => (person.companyId ? { _id: person.companyId._id, name: person.companyId.name } : ''))
      .filter(company => !!company)
      .filter(company => company.name.trim().length > 0);
    const uniqueCompanies = _.uniqWith(selectedCompanies, _.isEqual);
    return uniqueCompanies;
  }

  filterContacts(selectedValues) {
    return [
      ...new Set(
        [].concat(...selectedValues.map(({ contacts }) => contacts)).filter(contact => contact && !!contact.contact)
      ),
    ];
  }

  filterAddresses(selectedValues) {
    return [...new Set([].concat(...selectedValues.map(({ addresses }) => addresses)).filter(address => !!address))];
  }

  renderNames() {
    const { selectedNames, name } = this.state;
    const { styles } = this.props;
    return (
      <div>
        <Input className="mt-2" title={'Name'} value={name} onChange={text => this.onTextChange('name', text)} />
        <div>
          {selectedNames.map((name, index) => (
            <div className={styles.names} key={index} onClick={() => this.setState({ name })}>
              {name}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderTextField(fieldName, stateName) {
    const { styles } = this.props;
    return (
      <div>
        <Input
          className="mt-2"
          title={fieldName.toUpperCase()}
          value={this.state[fieldName]}
          onChange={text => this.onTextChange(fieldName, text)}
        />
        <div>
          {this.state[stateName].map((value, index) => (
            <div className={styles.names} key={index} onClick={() => this.setState({ [fieldName]: value })}>
              {value}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderTypes() {
    const { selectedTypes, type } = this.state;
    return (
      <Select items={selectedTypes} dropdownStyle={{ width: 250 }} title={'Type'}>
        {type}
      </Select>
    );
  }

  renderCompaniesDropdown() {
    const { selectedCompanies, companyName } = this.state;
    return (
      <Select items={selectedCompanies} dropdownStyle={{ width: 250 }} title={'Company'}>
        {companyName}
      </Select>
    );
  }

  renderCompanies() {
    const { selectedCompanies, companyName, companyResults } = this.state;
    return (
      <div>
        <div>
          {selectedCompanies.map((company, index) => (
            <div key={index} onClick={() => this.setState({ companyName: company.name, companyId: company._id })}>
              {company.name}
            </div>
          ))}
        </div>

        <CompanyInput
          className="mt-2"
          value={companyName}
          onChange={name => {
            this.searchCompany(name);
            this.setState({ companyName: name, companyResults: null });
            if (!name) this.onTextChange({ companyId: null });
          }}
          results={companyResults}
          onSelect={company => this.selectCompany(company)}
        />
      </div>
    );
  }

  renderPictures() {
    const { styles } = this.props;
    const { selectedPictures } = this.state;
    return (
      <div>
        {selectedPictures.map((picture, index) => (
          <div key={index}>
            <input
              type="radio"
              className={styles.checkbox}
              defaultChecked={false}
              onChange={e => this.onCheck(e.target.checked, index, picture, 'picture')}
            />{' '}
            <div
              className={styles.userImage}
              style={{
                backgroundImage: `url(${picture ? picture : Avatar})`,
              }}
            />
          </div>
        ))}
      </div>
    );
  }

  renderContacts() {
    const { styles } = this.props;
    const { selectedContacts } = this.state;

    return (
      <div>
        {selectedContacts.map((contact, index) => (
          <div key={index} className={styles.contactRow}>
            <input
              type="checkbox"
              className={styles.checkbox}
              defaultChecked={false}
              onChange={e => this.onCheck(e.target.checked, index, contact, 'contacts')}
            />{' '}
            <div>
              {contact.description ? contact.description : contact.contactType} : {contact.contact}
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderAddresses() {
    const { styles } = this.props;
    const { selectedAddresses } = this.state;
    return (
      <div>
        {selectedAddresses.map((address, index) => (
          <div key={index}>
            <input
              type="checkbox"
              className={styles.checkbox}
              defaultChecked={false}
              onChange={e => this.onCheck(e.target.checked, index, address, 'addresses')}
            />{' '}
            <AddressInfo key={index} address={address} />
          </div>
        ))}
      </div>
    );
  }

  onCheck = (isChecked, index, value, field) => {
    isChecked
      ? this.setState({ [field]: { [index]: value, ...this.state[field] } })
      : this.setState({ [field]: _.omit(this.state[field], index) });
  };

  finishMerge = () => {
    const { dispatchMerge } = this.props;
    const {
      name,
      companyId,
      profile_pic,
      type,
      slug,
      website_id,
      artistName,
      artistWebsite,
      tags,
      gender,
      netsuiteId,
      role,
      bandsintown,
      relationships,
      territoriesOfRepresentation,
      roles,
      social,
      representedByAgencies,
      representationDepartments,
      representationDepartmentIds,
    } = this.state;
    const contacts = Object.values(this.state.contacts);
    const addresses = Object.values(this.state.addresses);
    let values = {
      name,
      companyId,
      profile_pic,
      type,
      contacts,
      addresses,
      slug,
      gender,
      bandsintown,
      role,
      website_id,
      artistName,
      artistWebsite,
      relationships,
      tags,
      territoriesOfRepresentation,
      roles,
      social,
      representedByAgencies,
      representationDepartments,
      representationDepartmentIds,
    };
    type === 'Client' && Object.assign(values, { netsuiteId });
    dispatchMerge(values);
  };

  render() {
    const { styles, cancelMerge } = this.props;
    const {
      name,
      type,
      selectedTypes,
      selectedCompanies,
      selectedContacts,
      selectedAddresses,
      selectedPictures,
      selectedArtistName,
      selectedArtistWebsite,
      selectedSlugs,
      selectedTerr,
      selectedWebsiteIds,
      selectedBandsInTown,
      selectedGenders,
      selectedNetsuiteIds,
      selectedRoles,
    } = this.state;

    const potentialReconcileControls = [
      { potentialItems: selectedCompanies, action: () => this.renderCompanies() },
      { potentialItems: selectedContacts, action: () => this.renderContacts() },
      { potentialItems: selectedAddresses, action: () => this.renderAddresses() },
      { potentialItems: selectedPictures, action: () => this.renderPictures() },
      { potentialItems: selectedWebsiteIds, action: () => this.renderTextField('website_id', 'selectedWebsiteIds') },
      { potentialItems: selectedArtistName, action: () => this.renderTextField('artistName', 'selectedArtistName') },
      {
        potentialItems: selectedArtistWebsite,
        action: () => this.renderTextField('artistWebsite', 'selectedArtistWebsite'),
      },
      { potentialItems: selectedGenders, action: () => this.renderTextField('gender', 'selectedGenders') },
      {
        shouldShow: () => type === 'Client',
        potentialItems: selectedNetsuiteIds,
        action: () => this.renderTextField('netsuiteId', 'selectedNetsuiteIds'),
      },
      { potentialItems: selectedRoles, action: () => this.renderTextField('role', 'selectedRoles') },
      { potentialItems: selectedSlugs, action: () => this.renderTextField('slug', 'selectedSlugs') },
      {
        potentialItems: selectedTerr,
        action: () => this.renderTextField('territoriesOfRepresentation', 'selectedTerr'),
      },
      { potentialItems: selectedBandsInTown, action: () => this.renderTextField('bandsintown', 'selectedBandsInTown') },
    ];
    return (
      <div className={styles.outerDiv}>
        <div className="container">
          <span className={styles.title}>Data Merge</span>
          <div className={`row ${styles.row}`}>
            <div className="col-12">{name ? this.renderNames() : <Spinner />}</div>
          </div>
          {selectedTypes.length ? (
            <div className={`row ${styles.row}`}>
              <div className="col-4">{this.renderTypes()}</div>
            </div>
          ) : null}
          {potentialReconcileControls.map(({ potentialItems, action, shouldShow = () => true }) => {
            if (shouldShow() && potentialItems.length) {
              return (
                <div className={`row ${styles.row}`}>
                  <div className="col-12">{action()}</div>
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className="mt-3">
          <button onClick={cancelMerge} className={styles.cancelButton}>
            <i className={styles.cancelButtonIcon} />
            Cancel Merge
          </button>
          <button onClick={this.finishMerge} className={styles.finishButton}>
            <i className={styles.finishButtonIcon} />
            Finish Merge
          </button>
        </div>
      </div>
    );
  }
}
const withStyles = styled({
  finishButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    float: 'right',
  }),
  finishButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  cancelButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    float: 'left',
  }),
  cancelButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.cross
  ),
  checkbox: {
    float: 'left',
    marginRight: '10px',
  },
  userImage: {
    borderRadius: '50%',
    height: 35,
    width: 35,
    backgroundSize: 'cover',
    marginLeft: 20,
  },
  outerDiv: {
    width: '100%',
  },
  contactRow: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16pt',
    marginTop: 20,
    marginBottom: 20,
  },
  names: {
    fontWeight: 100,
    fontSize: '12px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  row: {
    marginTop: 20,
    border: '1px solid black',
    padding: '5px',
  },
});

const withState = connect(state => {
  const { people, peopleCount, peopleFilters, createdId } = state.dashboard;
  return {
    personDict: people && people.length !== 0 ? people.toDict('_id') : {},
    people,
    peopleCount,
    peopleFilters,
    createdId,
  };
});

export default withState(withStyles(MergeForm));

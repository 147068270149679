const buildProjectQuery = (filters, pagination, offset = 0, limit = 50) => {
  const { agentPerson, projectCompany, name, status, type } = filters;

  let _offset = (pagination && pagination.offset) || offset;
  let _limit = (pagination && pagination.limit) || limit;

  const projectQuery = [
    { $search: `active:true` },
    { $populate: 'literaryAgents' },
    { $populate: 'networks' },
    { $populate: 'productionCompany' },
    { $populate: 'productionCompanies' },
    { $populate: 'studios' },
    { $populate: 'talentAgents' },
    { $populate: 'agents' },
    { $populate: 'castings' },
    { $populate: 'castings.personId' },
    { $skip: _offset },
    { $limit: _limit },
    { $sort: 'name' },
  ];

  if (name) {
    projectQuery.push({ $search: `name:${name}` });
  }
  if (status) {
    projectQuery.push({ $search: `status:${status}` });
  }
  if (type) {
    projectQuery.push({ $search: `type:${type}` });
  }

  // TODO: $elemMatch was changed to an $and query, so these need to be rewritten
  if (agentPerson) {
    projectQuery.push({ $elemMatch: JSON.stringify({ talentAgents: { $eq: `${agentPerson._id}` } }) });
    projectQuery.push({ $elemMatch: JSON.stringify({ literaryAgents: { $eq: `${agentPerson._id}` } }) });
  }

  // TODO: $elemMatch was changed to an $and query, so these need to be rewritten
  if (projectCompany) {
    projectQuery.push({ $elemMatch: JSON.stringify({ networks: { $eq: `${projectCompany._id}` } }) });
    projectQuery.push({ $elemMatch: JSON.stringify({ studios: { $eq: `${projectCompany._id}` } }) });
    projectQuery.push({ $elemMatch: JSON.stringify({ productionCompanies: { $eq: `${projectCompany._id}` } }) });
  }

  return projectQuery;
};

export default buildProjectQuery;

import * as React from 'react';

import { connect } from 'react-redux';
import { styled } from 'react-free-style';
import { withRouter } from 'react-router-dom';

import Dropzone from 'react-dropzone';

class FileDropzone extends React.Component {
  constructor() {
    super();
    this.state = {
      dropzoneActive: false,
    };

    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
  }

  onDragEnter() {
    this.setState({
      dropzoneActive: true,
    });
  }

  onDragLeave() {
    this.setState({
      dropzoneActive: false,
    });
  }

  onDrop(files) {
    const { onDrop } = this.props;
    onDrop(files);

    this.setState({
      dropzoneActive: false,
    });
  }

  onDropRejected() {
    const { onDropRejected } = this.props;
    onDropRejected();

    this.setState({
      dropzoneActive: false,
    });
  }

  render() {
    const { accept, styles } = this.props;
    const { dropzoneActive } = this.state;

    const dropzoneProperties = {
      onDragEnter: this.onDragEnter,
      onDragLeave: this.onDragLeave,
      accept: accept,
      onDrop: this.onDrop,
      onDropRejected: this.onDropRejected,
    };

    return (
      <div className="container">
        <Dropzone className={styles.dropzone} {...dropzoneProperties}>
          {dropzoneActive && (
            <div className={styles.overlayStyle}>
              <h3>Drop File...</h3>
            </div>
          )}
          <div className={styles.fadedMore + ' text-center page-header py-5'}>
            <h1 className="display-1 text-muted m-1">
              <i className="fa fa-rotate-90 fa-sign-in" />
            </h1>
            <h5 className="text-info m-1">
              <strong>Choose a file</strong>
              <br />
              <small> or drag it here</small>
            </h5>
          </div>
        </Dropzone>
      </div>
    );
  }
}

const withStyles = styled({
  dropzone: {
    position: 'relative',
    outline: '2px dashed grey',
    outlineOffset: '-10px',
    backgroundColor: 'white',
  },
  // feel like this should be, or already is, global somewhere
  overlayStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '20px',
    background: 'rgba(39, 42, 43, 0.5)',
    transition: 'opacity 200ms ease-in-out',
    textAlign: 'center',
    color: '#fff',
    zIndex: 2000,
  },
  fadedMore: {
    opacity: '0.4',
  },
});

const withState = connect(() => {
  return {};
});

export default withRouter(withState(withStyles(FileDropzone)));

import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { Button, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';
import { styled } from 'react-free-style';
import { withRouter } from 'react-router-dom';

import FileDropzone from './file-dropzone';
import { uploadContacts } from '@united-talent-agency/julius-frontend-store';

class UploadContacts extends React.Component {
  constructor() {
    super();

    this.state = buildCleanState();

    this.onDrop = this.onDrop.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onDrop(files) {
    const file = files[0];

    this.setState({
      file: file,
      dropzoneSuccess: true,
    });

    if (file) {
      this.extractContacts(file);
    }
  }

  onDropRejected() {
    notify.show(this.state.errorMessage ? this.state.errorMessage : 'Not a valid CSV file', 'error');
    this.setState({
      dropzoneSuccess: false,
    });
  }

  onClear() {
    const state = buildCleanState();
    this.setState(state);
  }

  fileName() {
    return <span>{this.state.file && this.state.file.name}</span>;
  }

  onSubmit() {
    const { dispatch } = this.props;

    const task = async dispatch => {
      await dispatch(uploadContacts({ records: this.state.records }));
      const { data } = this.props;
      this.onClear();
      notify.show(`Number of contacts successfully uploaded: ${data.data.uploadStatus.success}`, 'success');
    };

    dispatch(task);
  }

  render() {
    const { file } = this.state;

    return (
      <div>
        <h1 className="display-4 m-1 mb-3">
          <span className="page-header" style={{ display: 'flex', alignItems: 'center' }}>
            Upload Contact
            <small> - CSV</small>
            {infoIcon}
          </span>
        </h1>

        {!file && (
          <div>
            <FileDropzone
              accept="text/csv, application/vnd.ms-excel"
              onDrop={this.onDrop}
              onDropRejected={this.onDropRejected}
            />
          </div>
        )}
        <div style={{ margin: 20, fontSize: '14px' }}>
          <u>Description:</u>
          <ul>
            <li>Creates a new person and contact for each row in a CSV file.</li>
            <li> Will create duplicate people/contacts if they already exist in the database</li>
            <li>Person type will be Industry Contact</li>
            <li> Does not index person/contact for type-ahead searches</li>
          </ul>
        </div>
        <div style={{ marginLeft: 20, fontSize: '14px' }}>
          <u>CSV Format (5 Fields):</u> <br />
          <span style={{ marginLeft: 20 }}>
            FIRST_NAME,&nbsp;&nbsp;LAST_NAME,&nbsp;&nbsp;COMPANY_NAME,&nbsp;&nbsp;CONTACT_TYPE<b>*</b>
            ,&nbsp;&nbsp;PHONE_NUMBER_OR_EMAIL
          </span>
          <br />
          <span style={{ papddingTop: 10, marginLeft: 20 }}>
            <b>*</b>CONTACT_TYPE must be Mobile, Work, or Email
          </span>
        </div>

        {file && (
          <div className="m-2">
            <div className="mb-2" style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="card" style={{ width: '50%' }}>
                <Table bsClass="m-1">
                  <tbody>
                    <tr>
                      <th>File Name</th>
                      <td>{this.fileName()}</td>
                    </tr>
                    <tr>
                      <th>Total Records</th>
                      <td>{this.state.status['totalRecords']}</td>
                    </tr>
                    <tr>
                      <th>Valid Records</th>
                      <td>{this.state.status['validRecords']}</td>
                    </tr>
                    <tr>
                      <th>Invalid Records</th>
                      <td>{this.state.status['invalidRecords']}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div
                style={{
                  alignSelf: 'flex-end',
                  flexGrow: 1,
                  textAlign: 'right',
                }}
              >
                <Button bsSize="small" bsStyle="danger" onClick={this.onClear}>
                  Clear
                </Button>
                <span className="ml-2">
                  <Button bsSize="small" bsStyle="success" onClick={this.onSubmit}>
                    Upload All
                  </Button>
                </span>
              </div>
            </div>
            <div>
              <div style={{ backgroundColor: 'white' }}>
                <Table striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th colSpan="6" className="p-1 text-center">
                        <span className="text-muted">Valid Records</span>
                      </th>
                    </tr>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Company</th>
                      <th>Communications</th>
                      <th>Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.records.map((record, index) => (
                      <tr key={index}>
                        <td>{record.firstName}</td>
                        <td>{record.lastName}</td>
                        <td>{record.company}</td>
                        <td>{record.communications}</td>
                        <td>{record.number}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        )}

        {this.state.invalidFileLines.length > 0 && (
          <div>
            <hr />
            <div style={{ backgroundColor: 'white' }}>
              <Table striped bordered condensed hover>
                <thead>
                  <tr>
                    <th className="p-1 text-center">
                      <span className="text-danger">Invalid Records</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.invalidFileLines.map((line, index) => (
                    <tr key={index}>
                      <td>{line}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    );
  }

  extractContacts(file) {
    let reader = new window.FileReader();
    reader.addEventListener('loadend', event => {
      const text = event.target.result;
      this.parseFile(text, file);
    });

    reader.readAsText(file);
  }

  parseFile(text, file) {
    let textLines = text.split('\n');
    const totalRecordsCount = textLines.length;

    const fileName = file.name;
    const status = buildStatus(fileName, totalRecordsCount);

    textLines = Array.from(new Set(text.split('\n')));
    let duplicateCount = totalRecordsCount !== textLines.length ? totalRecordsCount - textLines.length : 0;

    if (textLines.length <= 1) {
      const state = buildCleanState();

      state['errorMessage'] = 'The uploaded file is empty or has column headers only...';

      this.setState({ ...state });

      return;
    } else {
      textLines = textLines.slice(1);
    }

    textLines = textLines.map(textLine => {
      // trim trailing commas
      return textLine.trim().replace(/(^,)|(,$)/g, '');
    });

    const validLines = extractValidLines(textLines);
    const invalidLines = textLines.filter(x => !validLines.includes(x));

    if (duplicateCount) {
      invalidLines.push(duplicateCount + ' duplicate records...');
    }

    const records = validLines.map(line => {
      const lineTokens = line.split(',');

      return {
        firstName: lineTokens[0],
        lastName: lineTokens[1],
        company: lineTokens[2],
        communications: lineTokens[3],
        number: lineTokens[4],
      };
    });

    status['validRecords'] = validLines.length;
    status['invalidRecords'] = duplicateCount + invalidLines.length;

    this.setState({
      fileContents: text,
      fileLines: validLines,
      records: records,
      invalidFileLines: invalidLines,
      status: status,
    });
  }
}

function buildCleanState() {
  return {
    file: null,
    fileLines: [],
    records: [],
    invalidFileLines: [],
    status: [],
    errorMessage: null,
    showDialog: false,
  };
}

function extractValidLines(textLines) {
  return textLines.filter(textLine => {
    const lineTokens = textLine.split(',');
    let isValid = lineTokens.length === 5;
    isValid &= textLine.replace(',').length > 0;
    return isValid;
  });
}

function buildStatus(fileName, totalRecordsCount) {
  const status = [];

  status['fileName'] = fileName;
  status['totalRecords'] = totalRecordsCount;
  status['validRecords'] = 0;
  status['invalidRecords'] = 0;

  return status;
}

const infoIcon = (
  <span>
    <div data-tip="custom show" data-event="click focus">
      <small>
        <sup className="text-primary">
          <i style={{ opacity: 0.5 }} className="ml-2 fa fa-info-circle" aria-hidden="true" />
        </sup>
      </small>
    </div>
    <ReactTooltip place="right" type="light" globalEventOff="click">
      <div className="page-header">
        <h5>
          <strong>Contact Upload</strong>
          <small> File Format</small>
        </h5>
        <ul>
          <li>
            <em>The first row is ignored</em>
          </li>
          <li>
            <code>[First Name],[Last Name],[Company],[Communications],[Number]</code>
          </li>
        </ul>
      </div>
    </ReactTooltip>
  </span>
);

const withStyles = styled({
  faded: {
    opacity: '0.5',
  },
});

const withState = connect(state => {
  const { data } = state.dashboard;
  return {
    data,
  };
});

export default withRouter(withState(withStyles(UploadContacts)));

import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import ReactPaginate from 'react-paginate';
import { Table } from 'reactstrap';

import { Select, elements, icons } from '@united-talent-agency/julius-frontend-components';
import { Spinner } from '@united-talent-agency/components';
import { listPeople, updatePerson } from '@united-talent-agency/julius-frontend-store';

import NewPersonRow from './new-person-row';

const ALL_REQUEST_STATUSES = ['New', 'In Progress', 'Done'];
const MAX_ITEMS_PER_PAGE = 100;

class Requests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newPeople: [],
      status: 'All',
      skip: 0,
      total: 0,
    };
  }
  componentDidMount() {
    this.refreshPeople();
  }

  refreshPeople() {
    const { dispatch } = this.props;
    const { skip, status } = this.state;
    const queryStatuses =
      status === 'All'
        ? ALL_REQUEST_STATUSES.map(s => {
            return { 'approval.status': s };
          })
        : [{ 'approval.status': status }];
    const query = [
      { $skip: skip },
      { $limit: MAX_ITEMS_PER_PAGE },
      ...queryStatuses,
      { '$sort[approval.status]': -1 },
      { $populate: 'createdBy.personId' },
    ];

    dispatch(listPeople(query)).then(response => {
      this.setState({ newPeople: response.body.data, total: response.body.total });
    });
  }
  setPersonApproval = (_id, status) => {
    const { dispatch, user } = this.props;
    const payload = {
      _id,
      approval: { status, at: new Date(), by: user._id },
    };

    dispatch(updatePerson(payload)).then(() => {
      const { newPeople } = this.state;
      const person = newPeople.find(p => {
        return p._id.toString() === _id.toString();
      });
      person.approval.status = status;
      this.setState({ newPeople });
    });
  };

  handlePageClick = data => {
    let selected = data.selected;
    let offset = Math.ceil(selected * MAX_ITEMS_PER_PAGE);
    const params = { skip: offset };
    this.setState(params, this.refreshPeople);
  };

  getStatusList(styles) {
    const allStatus = {
      key: 'All',
      content: 'All',
      active: 'All' === this.state.status,
      className: styles.menuItem,
      onClick: () => {
        const params = { status: 'All', skip: 0 };
        this.setState(params, this.refreshPeople);
      },
    };

    const individualStatuses = ALL_REQUEST_STATUSES.map(status => ({
      key: status,
      content: status,
      active: status === this.state.status,
      className: styles.menuItem,
      onClick: () => {
        const params = { status, skip: 0 };
        this.setState(params, this.refreshPeople);
      },
    }));

    return [allStatus, ...individualStatuses];
  }

  render() {
    const { styles } = this.props;
    const { newPeople, total } = this.state;
    const statuses = this.getStatusList(styles);

    return (
      <div className={styles.container}>
        <h1>New People Created</h1>
        <div className={styles.filterBar}>
          <span className={styles.filterLabel}>{'Filters: '}</span>
          <Select className={styles.filterField} items={statuses} title={'Status'}>
            {this.state.status}
          </Select>
          <span
            className={styles.clearFilters}
            onClick={() => {
              const params = { status: 'All', skip: 0 };
              this.setState(params, this.refreshPeople);
            }}
          >
            Clear Filters
          </span>
        </div>

        <Table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.thStatus}>Status</th>
              <th>Source</th>
              <th>Name</th>
              <th>Creator</th>
              <th>Created</th>
              <th className={styles.thActions}>Change Status To:</th>
            </tr>
          </thead>
          <tbody>
            {newPeople ? (
              newPeople.map(person => {
                return (
                  <NewPersonRow
                    person={person}
                    key={person._id}
                    onStatusChanged={(id, status) => {
                      this.setPersonApproval(id, status);
                    }}
                  />
                );
              })
            ) : (
              <tr>
                <td>
                  <Spinner size={60} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName={styles.breakPagination}
          pageCount={Math.ceil(total / MAX_ITEMS_PER_PAGE)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={styles.pagination}
          subContainerClassName={styles.pages}
          activeClassName={styles.activePagination}
        />
      </div>
    );
  }
}

const withStyles = styled({
  container: {
    flex: 1,
  },
  filterBar: {
    flex: 1,
    height: '70px',
  },
  filterLabel: {
    float: 'left',
    fontSize: 16,
    fontWight: 1000,
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '10px',
  },
  pagination: {
    display: 'inline-block',
    float: 'right',
    paddingLeft: 0,
    marginLeft: 0,
    color: 'black',
    '& > li > a': {
      color: 'black',
    },
    '& > li': {
      display: 'inline-block',
      color: 'black',
      margin: 5,
      padding: 5,
    },
    '& > li.selected': {
      background: 'black',
    },
    '& > li.selected > a': {
      color: 'white',
    },
    cursor: 'pointer',
  },
  table: {
    background: '#fff',
    verticalAlign: 'middle',
    fontSize: 12,
  },
  filterField: {
    width: '150px',
    marginRight: 10,
    float: 'left',
  },
  header: {
    color: '#000',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '1',
    background: 'white',
    marginBottom: 5,
  },
  newButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    float: 'right',
  }),
  newButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  menuItem: {
    zIndex: 1000000, //we mean it!
  },
  clearFilters: {
    float: 'left',
    fontSize: 10,
    color: 'black',
    textDecoration: 'underline',
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '15px',
    cursor: 'pointer',
  },
  thStatus: {
    width: 100,
  },
  thActions: {
    width: 287,
  },
});

const withState = connect(store => {
  return { user: store.user };
});

export default withRouter(withState(withStyles(Requests)));

import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';

import { colors, elements } from '@united-talent-agency/julius-frontend-components';
import { updateDataRequest, countNewDataRequests } from '@united-talent-agency/julius-frontend-store';

import { openPersonProfile } from '../../../../../support/person';
import { toDateString } from '../../../../../support/date';

class RequestsRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.request,
    };
  }

  async setStatus(status) {
    await this.updateRequest(this.state._id, { status });
    this.setState({ status });
  }

  async updateRequest(id, payload) {
    const { dispatch } = this.props;
    await dispatch(updateDataRequest(id, payload));
    dispatch(countNewDataRequests());
  }

  render() {
    const { styles } = this.props;
    const { request, source, status, targetId, userId, requested, target } = this.state;

    const isNew = status === 'New';

    return (
      <tr>
        <td className={isNew ? styles.tdNew : styles.td}>{status}</td>
        <td className={styles.td}>
          {/* TODO: Abstract to handle various sources */}
          {targetId && target === 'person' ? (
            <div className={styles.profileLink} onClick={() => openPersonProfile(targetId)}>
              {_.capitalize(source)} <i className={`ml-1 fa fa-external-link align-middle`} aria-hidden="true" />
            </div>
          ) : (
            <span />
          )}
          {targetId && target === 'company' ? (
            <div className={styles.profileLink}>
              {_.capitalize(source)} <i className={`ml-1 fa fa-external-link align-middle`} aria-hidden="true" />
            </div>
          ) : (
            <span />
          )}
        </td>
        <td className={styles.td}>{request}</td>
        <td>
          {userId && userId.first_name} {userId && userId.last_name}
        </td>
        <td style={{ width: '10%' }}>{requested ? toDateString(new Date(requested)) : 'unknown'} </td>
        <td>
          {this.renderButton('New')}
          {this.renderButton('In Progress')}
          {this.renderButton('Done')}
        </td>
      </tr>
    );
  }

  renderButton(status) {
    const { styles } = this.props;
    return (
      <button className={classnames(styles.button, styles.defaultButton)} onClick={() => this.setStatus(status)}>
        {status}
      </button>
    );
  }
}

const withStyles = styled({
  button: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: 5,
  }),
  defaultButton: {
    borderColor: colors.darkText,
    width: 78,
    height: 25,
  },
  td: {
    fontSize: 12,
    color: colors.text,
  },
  tdNew: {
    fontSize: 12,
    color: colors.green,
  },
  profileLink: {
    cursor: 'pointer',
  },
});

const withState = connect(() => {
  return {};
});

export default withState(withStyles(RequestsRow));

import { get, remove } from './index.js';

/**
 * Makes an API call to the DELETE /groups resource
 *    deleteGroup("5c7eb9321f5cf20010de09d5") =>
 *      /groups/5c7eb9321f5cf20010de09d5
 *
 * @param {string} id the mongo id of the group to delete
 *
 * @returns {Promise} a Promise that represents the response object
 **/
export const deleteGroup = async id => {
  const response = await remove(`/groups/${id}`);
  return response.body.data;
};

/**
 * Makes an API call to the GET /groups resource
 *    getGroups("asdf", 25, 50, "name") =>
 *      /groups?search="{searchText}"&skip={skip}&limit={limit}&sort={sort} =>
 *      /groups?search=%22asdf%22&skip=25&limit=50&sort=name
 *
 * @param {string} searchText The text to search for in groups - exact search is automatically applied here by surrounding the search text with double-quotes
 * @param {number} skip the number of records to skip
 * @param {number} limit the number of records to retrieve
 * @param {string} sort the property with which to sort results
 *
 * @returns {Promise} a Promise that represents the response object
 **/
export const getGroups = (searchText, skip, limit, sort, _get = get) => {
  const query = [];
  if (searchText) {
    //wrap search text in double quotes to force an exact match search
    query.push({ search: `"${searchText}"` });
  }

  if (skip) {
    query.push({ skip });
  }

  if (limit) {
    query.push({ limit });
  }

  if (sort) {
    query.push({ sort });
  }

  const execPlan = _get(`/groups`, query).then(response => {
    const { errors, data, meta } = (response || {}).body || {};
    if (errors) {
      //errors is an array, but v3 only returns a single error in the array
      //this is for api-handled errors,
      //  - unhandled errors shouldnt get this far (such as network outage)
      console.error('Handled API Error from /groups', errors);
      return Promise.reject(errors[0]);
    }
    const results = { data, meta };
    return results;
  });
  return execPlan;
};

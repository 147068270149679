import { datadogRum } from '@datadog/browser-rum';

const HOST_MAP = {
  'masterdata.unitedtalent.com': 'prod',
  'masterdata.staging.unitedtalent.com': 'staging',
  'masterdata.qa.unitedtalent.com': 'dev',
  localhost: 'local',
};

/**
 * Initializes data dog for the appropriate host (environment)
 *
 * @param { string } host:
 * @param { string[] } allowableEnvs: a list of allowable environments to track.
 */
export const initializeDataDog = (host, allowableEnvs) => {
  const env = HOST_MAP[host];
  if (allowableEnvs.includes(env)) {
    datadogRum.init({
      env,
      version: '0.3.1',
      service: 'data-steward-united-talent',
      site: 'us3.datadoghq.com',
      applicationId: 'eb32d054-619f-4840-b999-da897c591d91',
      clientToken: 'pub9fec5c51caa7de296fe191afe58bbb1a',
      allowedTracingOrigins: ['https://api.unitedtalent.com', /https:\/\/.*\.unitedtalent\.com/],
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
    });
  }
};

import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import ReactPaginate from 'react-paginate';
import { Table } from 'reactstrap';

import { Select, elements, icons } from '@united-talent-agency/julius-frontend-components';
import { loadDataRequests, updateDataRequest } from '@united-talent-agency/julius-frontend-store';
import { Spinner } from '@united-talent-agency/components';

import RequestsRow from './requests-row';

const ALL_REQUEST_STATUSES = ['New', 'In Progress', 'Done'];

class Requests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
      personName: '',
      personTitle: '',
      personCompany: '',
      personType: '',
      created: '',
      status: 'All',
    };
    this.refreshRequests();
  }

  async refreshRequests(offset = 0, statuses = ALL_REQUEST_STATUSES) {
    const { dispatch, dataRequest } = this.props;
    const query = [
      { $skip: offset },
      { $limit: dataRequest.limit },
      ...statuses.map(status => {
        return { status };
      }),
      { '$sort[status]': -1 },
      { '$sort[requested]': -1 },
      { $populate: 'userId' },
    ];
    dispatch(loadDataRequests(query));
  }

  updateRequest(id, payload) {
    const { dispatch } = this.props;
    dispatch(updateDataRequest(id, payload));
  }

  handlePageClick = data => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.props.dataRequest.limit);
    this.setState({ skip: offset });
    const statuses = this.state.status === 'All' ? ALL_REQUEST_STATUSES : [this.state.status];
    this.refreshRequests(offset, statuses);
  };

  render() {
    const { dataRequest, styles } = this.props;

    const statuses = this.getStatusList(styles);

    return (
      <div className={styles.container}>
        <h1>Handle - Data Requests</h1>
        <div className={styles.filterBar}>
          <span className={styles.filterLabel}>{'Filters: '}</span>
          <Select className={styles.filterField} items={statuses} title={'Status'}>
            {this.state.status}
          </Select>
          <span
            className={styles.clearFilters}
            onClick={() => {
              this.refreshRequests();
              this.setState({ status: 'All' });
            }}
          >
            {' Clear Filters '}
          </span>
        </div>

        <Table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.thStatus}>Status</th>
              <th>Source</th>
              <th>Request</th>
              <th>Requestor</th>
              <th>Requested</th>
              <th className={styles.thActions}>Change Status To:</th>
            </tr>
          </thead>
          <tbody>
            {dataRequest ? (
              dataRequest.dataRequests.map(request => {
                return <RequestsRow request={request} key={request._id} />;
              })
            ) : (
              <tr>
                <td>
                  <Spinner size={60} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName={styles.breakPagination}
          pageCount={Math.ceil(dataRequest.total / dataRequest.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={styles.pagination}
          subContainerClassName={styles.pages}
          activeClassName={styles.activePagination}
        />
      </div>
    );
  }

  getStatusList(styles) {
    const allStatus = {
      key: 'All',
      content: 'All',
      active: 'All' === this.state.status,
      className: styles.menuItem,
      onClick: () => {
        this.refreshRequests(0, ALL_REQUEST_STATUSES);
        this.setState({ status: 'All' });
      },
    };

    const individualStatuses = ALL_REQUEST_STATUSES.map(status => ({
      key: status,
      content: status,
      active: status === this.state.status,
      className: styles.menuItem,
      onClick: () => {
        this.refreshRequests(0, [status]);
        this.setState({ status });
      },
    }));

    return [allStatus, ...individualStatuses];
  }
}

const TABLE_WIDTH = 1000;

const withStyles = styled({
  container: {
    width: TABLE_WIDTH,
  },
  filterBar: {
    width: TABLE_WIDTH,
    height: '70px',
  },
  filterLabel: {
    float: 'left',
    fontSize: 16,
    fontWight: 1000,
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '10px',
  },
  pagination: {
    display: 'inline-block',
    float: 'right',
    paddingLeft: 0,
    marginLeft: 0,
    color: 'black',
    '& > li > a': {
      color: 'black !important',
    },
    '& > li': {
      display: 'inline-block',
      color: 'black !important',
      margin: 5,
      padding: 5,
    },
    '& > li.selected': {
      background: 'black',
    },
    '& > li.selected > a': {
      color: 'white !important',
    },
  },
  table: {
    background: '#fff',
    verticalAlign: 'middle',
  },
  filterField: {
    width: '150px',
    marginRight: 10,
    float: 'left',
  },
  header: {
    color: '#000',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '1',
    background: 'white',
    marginBottom: 5,
  },
  newButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    float: 'right',
  }),
  newButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  menuItem: {
    zIndex: 1000000,
  },
  clearFilters: {
    float: 'left',
    fontSize: 8,
    color: 'black',
    textDecoration: 'underline',
    textAlign: 'center',
    marginRight: '20px',
    marginTop: '15px',
    cursor: 'pointer',
  },
  thStatus: {
    width: 100,
  },
  thActions: {
    width: 287,
  },
});

const withState = connect(state => {
  const { dataRequest } = state;
  const { createdId } = state.dashboard;
  return { createdId, dataRequest };
});

export default withRouter(withState(withStyles(Requests)));
